// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

import GalleryController from "./gallery_controller"
application.register("gallery", GalleryController)

import FancyboxController from "./fancybox_controller"
application.register("fancybox", FancyboxController)

import AudioRecorderController from "./audio_recorder_controller";
application.register("audio-recorder", AudioRecorderController);

import RevealController from '@stimulus-components/reveal'
application.register('reveal', RevealController)

import MessagesController from "./messages_controller"
application.register('messages', MessagesController)