// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import "justified-layout"
import { Fancybox } from "@fancyapps/ui";
import FamilyTree from "@balkangraph/familytree.js"

import "trix"
import "@rails/actiontext"

function getQueryParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

document.addEventListener("turbo:load", function() {
    let options = {
        Toolbar: {
            display: {
                left: ["infobar"],
                middle: [],
                right: ["slideshow", "download", "thumbs", "close"],
            },
        },
    };

    Fancybox.bind('[data-fancybox]', options);

    let tree = document.getElementById("tree");

    if (tree) {
        let people = tree.dataset.people;
        let levels = tree.dataset.levels;
        var data = JSON.parse(people);
        let nodesToCollapse = [];
        let [cx, cy] = [0, 0];
        let plus = `<circle cx="${cx}" cy="${cy}" r="20" fill="#6b9999" stroke="#fff" stroke-width="3"></circle>
            <line x1="-11" y1="0" x2="11" y2="0" stroke-width="3" stroke="#fff"></line>
            <line x1="0" y1="-11" x2="0" y2="11" stroke-width="3" stroke="#fff"></line>`;
        let minus = `<circle cx="${cx}" cy="${cy}" r="20" fill="#6b9999" stroke="#fff" stroke-width="1"></circle>
            <line x1="-11" y1="0" x2="11" y2="0" stroke-width="3" stroke="#fff"></line>`;

        console.log(people);

        FamilyTree.templates.fhf = Object.assign({}, FamilyTree.templates.tommy);
        FamilyTree.templates.fhf.size = [200, 180];
        FamilyTree.templates.fhf.node = '<rect x="0" y="0" width="200" height="180" stroke-width="0" fill="#2f3437" stroke="#4D4D4D" rx="5"></rect>';
        FamilyTree.templates.fhf.defs =`<g transform="matrix(1,0,0,1,-12,-9)" id="plus">${minus}</g><g transform="matrix(1,0,0,1,-12,-9)" id="minus">${minus}</g>`;
        FamilyTree.templates.fhf.up = '<use x="85" y="-15" xlink:href="#base_up" />';
        FamilyTree.templates.fhf.field_0 = '<text style="font-size: 24px;" fill="#ffffff" x="100" y="80" text-anchor="middle" data-width="200" data-text-overflow="multiline">{val}</text>';
        FamilyTree.templates.fhf.field_1 = '<text style="font-size: 20px;" fill="#ffffff" x="100" y="120" text-anchor="middle" data-width="200" data-text-overflow="multiline">{val}</text>';
        FamilyTree.templates.fhf.img_0 = `<circle id="base_img_0_stroke" fill="#b1b9be" cx="45" cy="62" r="37"/><image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="20" y="-15" width="80" height="80"></image>`;
        // FamilyTree.templates.fhf.img_0 = '';
        FamilyTree.templates.fhf.plus = plus;
        FamilyTree.templates.fhf.minus = minus;

        var chart = new FamilyTree(tree, {
            // mouseScrool: FamilyTree.none,
            layout: FamilyTree.layout.normal,
            // layout: FamilyTree.layout.mixed,
            // layout: FamilyTree.layout.tree,
            // layout: FamilyTree.layout.treeLeftOffset,
            // layout: FamilyTree.layout.treeRightOffset,
            // layout: FamilyTree.layout.treeLeft,
            // layout: FamilyTree.layout.treeRight,
            // layout: FamilyTree.layout.grid,
            scaleInitial: FamilyTree.match.boundary, // 0.65,
            scaleMax: 0.8,
            enableSearch: true,
            // searchDisplayField: "fullname",
            toolbar: {
                layout: false,
                zoom: true,
                fit: true,
                expandAll: true,
                fullScreen: false
            },
            // menu:{
            //     svg: { text: "Export SVG" },
            //     csv: { text: "Export CSV" }
            // },
            anim: {
                duration: 400
            },
            padding: 50,
            zoom: {speed: 1000, smooth: 100},
            mouseScrool: FamilyTree.action.none,
            partnerNodeSeparation: 100,
            partnerChildrenSplitSeparation: 20,
            siblingSeparation: 100,
            levelSeparation: 100,
            nodeBinding: {
                field_0: "full_name",
                field_1: "lifetime",
                field_2: "hidden",
                field_3: "highlighted",
                img_0: "img"
            },
            template: 'fhf',
            nodeMouseClick: FamilyTree.action.none,
            // collapse: {level: levels, allChildren: true},
            // expand: {nodes: [9], allChildren: true}
            // roots: [4] // Do not use in V1 of Balkan as it doesn't do anything. Move "root" People to top of array instead.
        });

        chart.load(data);

        chart.onNodeClick((args) => {
            let data = chart.get(args.node.id);
            let node_detail_dialog = document.querySelector('#node_detail_dialog');

            fetch("/ajax/node_detail/" + args.node.id)
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                var parser = new DOMParser();
                var html = parser.parseFromString(data, "text/html");

                node_detail_dialog.innerHTML = new XMLSerializer().serializeToString(html);
                node_detail_dialog.showModal();

                document.querySelectorAll('.person-chip').forEach((person_chip) => {
                    person_chip.addEventListener("click", function (e) {
                        node_detail_dialog.close();
                        let node_id = e.target.dataset.person_id;

                        chart.center(node_id, {}, { childrenState: false, parentState: false });
                        setTimeout(() => {
                            chart.ripple(node_id);
                        }, 1000)
                        
                    });
                })

                document.querySelector('.close-button').addEventListener("click", () => {
                    node_detail_dialog.close();
                });
            })
            .catch(function (err) {
                console.warn("Something went wrong.", err);
            });
        });

        chart.on('render-link', function (sender, args) {
            if (args.cnode.ppid != undefined) {
                cx = args.p.xa + 12;
                cy = args.p.ya + 9;
                args.html += `<use data-ctrl-ec-id="${args.node.id}" xlink:href="#minus" x="${cx}" y="${cy}"/>`;
            }
        });

        chart.on('field', function (sender, args) {
            if (args.data["hidden"]) {
            args.value = `[ HIDDEN ]`;
            }
    });

        chart.onInit(() => {
            chart.fit();

            chart.config.nodes.forEach((n) => {
                let node = chart.getNode(n.id);
                if (node.sl > (levels - 1)) {
                    nodesToCollapse.push(node.id);
                }
            });
            chart.expandCollapse(4, [], nodesToCollapse)
        })

        var resizeTimeout = null;
        window.onresize = function() {
            if (resizeTimeout){
                clearTimeout(resizeTimeout);
                resizeTimeout = null;
            }
            resizeTimeout = setTimeout(function(){
                chart.fit()
            }, 500);
        }

        const person = getQueryParam('person');
        if (person) {
            setTimeout(() => {
                chart.center(person, {}, { childrenState: true, parentState: true });
                // chart.ripple(person);
            }, 2000)
        }

    }

   
});
