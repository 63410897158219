import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["recordButton", "submitButton", "audio"];
  static values = {
    mediaId: Number,
    mediaType: String,
    user: Number
  }

  connect() {
    this.stream = null;
    this.mediaRecorder = null;
    this.chunks = [];
    this.audioBlob = null;
    this.isRecording = false;

    this.recordButtonTarget.disabled = false;
    this.submitButtonTarget.disabled = true;
  }

  async toggleRecording() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      await this.startRecording();
    }
  }

  async startRecording() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(this.stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.chunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        this.audioBlob = new Blob(this.chunks, { type: "audio/webm" });
        this.audioTarget.src = URL.createObjectURL(this.audioBlob);
        this.chunks = [];
        this.submitButtonTarget.disabled = false;
      };

      this.mediaRecorder.start();
      this.isRecording = true;
      this.updateRecordButtonText();
    } catch (error) {
      console.error("Error accessing the microphone:", error);
      alert("Could not access your microphone. Please check your settings.");
    }
  }

  stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
      this.stream.getTracks().forEach((track) => track.stop());
      this.isRecording = false;
      this.updateRecordButtonText();
    }
  }

  updateRecordButtonText() {
    this.recordButtonTarget.querySelector('span').textContent = this.isRecording ? "Stop Recording" : "Start Recording";
    this.recordButtonTarget.className = this.isRecording ? "button record-button stop" : "button record-button record";
  }

  async submitAudio() {
    if (!this.audioBlob) {
      console.error("No audio blob available for submission.");
      return;
    }

    const formData = new FormData();
    formData.append("audio_memory[file]", this.audioBlob, "audio_memory.webm");
    formData.append("audio_memory[media_id]", this.mediaIdValue);
    formData.append("audio_memory[media_type]", this.mediaTypeValue);
    formData.append("audio_memory[user_id]", this.userValue);

    try {
      const response = await fetch("/audio_memories", {
        method: "POST",
        headers: {
          "Accept": "text/vnd.turbo-stream.html", // Turbo Stream header
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload audio");
      }

      const html = await response.text()
      Turbo.renderStreamMessage(html)

      console.log("Audio uploaded successfully");
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  }
}
